import {PageSection} from "gatsby-theme-portfolio-minimal/src/types";
import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image';
import {useLocalDataSource} from "gatsby-theme-portfolio-minimal/src/sections/Hero/data";
import {Animation} from "gatsby-theme-portfolio-minimal/src/components/Animation/index";
import {Section} from "gatsby-theme-portfolio-minimal/src/components/Section/index";


import * as classes from 'gatsby-theme-portfolio-minimal/src/sections/Hero/style.module.css';
import "./customHero.css"
import {SocialProfiles} from "./SocialProfiles";
export function HeroSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allHeroJson.sections[0];

    return (
        <Animation type="fadeUp" delay={400}>
            <Section anchor={props.sectionId} additionalClasses={[classes.Hero]}>
                <div className={"hero-wrapper-text"} >
                    <div className={classes.Intro}>
                        {data.intro && <span className={classes.ImagePrefix}>{data.intro}</span>}
                        {data.image.src && (
                            <Animation className={classes.Image} type="waving-hand" duration={2500} iterationCount={3}>
                                <GatsbyImage
                                    image={data.image.src.childImageSharp.gatsbyImageData}
                                    alt={data.image.alt || `Intro Image`}
                                    loading="eager"
                                />
                            </Animation>
                        )}
                    </div>

                    <h1 className={classes.Title} >
                        <div className={"hero-wrapper-text2"} style={{display:"flex"}}>
                              We are <div className="gradient" >FTSORK</div>
                        </div>

                    </h1>
                </div>
                <div style={{borderWidth:"1px",borderColor:"var(--secondary-color)",borderStyle:"solid",marginBottom:"1rem"}}/>

                <h2 style={{fontSize:"1.45rem"}} className={classes.Subtitle}>
                    {data.subtitle.prefix}
                    <u>{data.subtitle.highlight}</u>
                    {data.subtitle.suffix}
                </h2>
                <br/>
                <p style={{margin:0}}>{data.description}</p>
                <Animation type="fadeLeft" delay={600}>
                    {data.socialProfiles && (
                        <SocialProfiles from={data.socialProfiles.from} showIcon={data.socialProfiles.showIcons} />
                    )}
                </Animation>
            </Section>
        </Animation>
    );
}
